.global-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

form {
  padding-top: 10px;
  font-size: 14px;
  margin-top: 30px;
}

.card-title {
  font-weight: 300;
}

.btn {
  font-size: 14px;
  margin-top: 20px;
}

.login-form {
  /* width: 350px; */
  width: 40%;
  margin: 20px;
  border-radius: 20px !important;
}

.sign-up {
  text-align: center;
  padding: 20px 0 0;
}

.alert {
  margin-bottom: -30px;
  font-size: 13px;
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .login-form {
    width: 90% !important;
    margin: 20px;
    border-radius: 20px !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 800px) {
  .login-form {
    width: 90% !important;
    margin: 20px;
    border-radius: 20px !important;
  }
}
